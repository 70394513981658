import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const BounusEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Name"),
		start_data: yup.string().required().label("Start Data"),
		end_data: yup.number().required().label("End Data"),
		value: yup.number().required().label("Value"),
		percentage: yup.number().required().label("Percentage")
	});
	// form default values
	const formDefaultValues = {
		name: '', 
		start_data: new Date(), 
		end_data: '', 
		value: '', 
		percentage: "0", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/bounus`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="BounusEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Name *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="name"  onChange={formik.handleChange}  value={formik.values.name}   label="Name" type="text" placeholder="accedere Name"        className={inputClassName(formik?.errors?.name)} />
                                                <ErrorMessage name="name" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Start Data *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="start_data" showButtonBar className={inputClassName(formik?.errors?.start_data)} dateFormat="yy-mm-dd" value={formik.values.start_data} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="start_data" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                End Data *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="end_data"  onChange={formik.handleChange}  value={formik.values.end_data}   label="End Data" type="number" placeholder="accedere End Data"  min={0}  step="any"    className={inputClassName(formik?.errors?.end_data)} />
                                                <ErrorMessage name="end_data" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Value *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="value"  onChange={formik.handleChange}  value={formik.values.value}   label="Value" type="number" placeholder="accedere Value"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.value)} />
                                                <ErrorMessage name="value" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Percentage *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="percentage"  onChange={formik.handleChange}  value={formik.values.percentage}   label="Percentage" type="number" placeholder="accedere Percentage"  min={0}  step="any"    className={inputClassName(formik?.errors?.percentage)} />
                                                <ErrorMessage name="percentage" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiornare" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
BounusEditPage.defaultProps = {
	primaryKey: 'id_bonus',
	pageName: 'bounus',
	apiPath: 'bounus/edit',
	routeName: 'bounusedit',
	submitButtonLabel: "Aggiornare",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default BounusEditPage;
