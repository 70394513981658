import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AppointmentsList from 'pages/appointments/List';
import AppointmentsView from 'pages/appointments/View';
import AppointmentsAdd from 'pages/appointments/Add';
import AppointmentsEdit from 'pages/appointments/Edit';
import BounusList from 'pages/bounus/List';
import BounusView from 'pages/bounus/View';
import BounusAdd from 'pages/bounus/Add';
import BounusEdit from 'pages/bounus/Edit';
import ClientsList from 'pages/clients/List';
import ClientsView from 'pages/clients/View';
import ClientsAdd from 'pages/clients/Add';
import ClientsEdit from 'pages/clients/Edit';
import DaysList from 'pages/days/List';
import DaysView from 'pages/days/View';
import DaysAdd from 'pages/days/Add';
import DaysEdit from 'pages/days/Edit';
import DaysoperatorsList from 'pages/daysoperators/List';
import DaysoperatorsView from 'pages/daysoperators/View';
import DaysoperatorsAdd from 'pages/daysoperators/Add';
import DaysoperatorsEdit from 'pages/daysoperators/Edit';
import DevicesList from 'pages/devices/List';
import DevicesView from 'pages/devices/View';
import DevicesAdd from 'pages/devices/Add';
import DevicesEdit from 'pages/devices/Edit';
import DivinatorytoolsList from 'pages/divinatorytools/List';
import DivinatorytoolsView from 'pages/divinatorytools/View';
import DivinatorytoolsAdd from 'pages/divinatorytools/Add';
import DivinatorytoolsEdit from 'pages/divinatorytools/Edit';
import FeedbackList from 'pages/feedback/List';
import FeedbackView from 'pages/feedback/View';
import FeedbackAdd from 'pages/feedback/Add';
import FeedbackEdit from 'pages/feedback/Edit';
import FilesList from 'pages/files/List';
import FilesView from 'pages/files/View';
import FilesAdd from 'pages/files/Add';
import FilesEdit from 'pages/files/Edit';
import FiletypesList from 'pages/filetypes/List';
import FiletypesView from 'pages/filetypes/View';
import FiletypesAdd from 'pages/filetypes/Add';
import FiletypesEdit from 'pages/filetypes/Edit';
import HoursList from 'pages/hours/List';
import HoursView from 'pages/hours/View';
import HoursAdd from 'pages/hours/Add';
import HoursEdit from 'pages/hours/Edit';
import HoursoperatorsList from 'pages/hoursoperators/List';
import HoursoperatorsView from 'pages/hoursoperators/View';
import HoursoperatorsAdd from 'pages/hoursoperators/Add';
import HoursoperatorsEdit from 'pages/hoursoperators/Edit';
import JobsList from 'pages/jobs/List';
import JobsView from 'pages/jobs/View';
import JobsAdd from 'pages/jobs/Add';
import JobsEdit from 'pages/jobs/Edit';
import OperatordevicesList from 'pages/operatordevices/List';
import OperatordevicesView from 'pages/operatordevices/View';
import OperatordevicesAdd from 'pages/operatordevices/Add';
import OperatordevicesEdit from 'pages/operatordevices/Edit';
import OperatordivinatorytoolsList from 'pages/operatordivinatorytools/List';
import OperatordivinatorytoolsView from 'pages/operatordivinatorytools/View';
import OperatordivinatorytoolsAdd from 'pages/operatordivinatorytools/Add';
import OperatordivinatorytoolsEdit from 'pages/operatordivinatorytools/Edit';
import OperatorsList from 'pages/operators/List';
import OperatorsView from 'pages/operators/View';
import OperatorsEdit from 'pages/operators/Edit';
import OperatorstatusesList from 'pages/operatorstatuses/List';
import OperatorstatusesView from 'pages/operatorstatuses/View';
import OperatorstatusesAdd from 'pages/operatorstatuses/Add';
import OperatorstatusesEdit from 'pages/operatorstatuses/Edit';
import PermissionsList from 'pages/permissions/List';
import PermissionsView from 'pages/permissions/View';
import PermissionsAdd from 'pages/permissions/Add';
import PermissionsEdit from 'pages/permissions/Edit';
import QualificationsList from 'pages/qualifications/List';
import QualificationsView from 'pages/qualifications/View';
import QualificationsAdd from 'pages/qualifications/Add';
import QualificationsEdit from 'pages/qualifications/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import TypesdocumentsList from 'pages/typesdocuments/List';
import TypesdocumentsView from 'pages/typesdocuments/View';
import TypesdocumentsAdd from 'pages/typesdocuments/Add';
import TypesdocumentsEdit from 'pages/typesdocuments/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* appointments pages routes */}
				<Route path="/appointments" element={<AppointmentsList />} />
				<Route path="/appointments/:fieldName/:fieldValue" element={<AppointmentsList />} />
				<Route path="/appointments/index/:fieldName/:fieldValue" element={<AppointmentsList />} />
				<Route path="/appointments/view/:pageid" element={<AppointmentsView />} />
				<Route path="/appointments/add" element={<AppointmentsAdd />} />
				<Route path="/appointments/edit/:pageid" element={<AppointmentsEdit />} />

				{/* bounus pages routes */}
				<Route path="/bounus" element={<BounusList />} />
				<Route path="/bounus/:fieldName/:fieldValue" element={<BounusList />} />
				<Route path="/bounus/index/:fieldName/:fieldValue" element={<BounusList />} />
				<Route path="/bounus/view/:pageid" element={<BounusView />} />
				<Route path="/bounus/add" element={<BounusAdd />} />
				<Route path="/bounus/edit/:pageid" element={<BounusEdit />} />

				{/* clients pages routes */}
				<Route path="/clients" element={<ClientsList />} />
				<Route path="/clients/:fieldName/:fieldValue" element={<ClientsList />} />
				<Route path="/clients/index/:fieldName/:fieldValue" element={<ClientsList />} />
				<Route path="/clients/view/:pageid" element={<ClientsView />} />
				<Route path="/clients/add" element={<ClientsAdd />} />
				<Route path="/clients/edit/:pageid" element={<ClientsEdit />} />

				{/* days pages routes */}
				<Route path="/days" element={<DaysList />} />
				<Route path="/days/:fieldName/:fieldValue" element={<DaysList />} />
				<Route path="/days/index/:fieldName/:fieldValue" element={<DaysList />} />
				<Route path="/days/view/:pageid" element={<DaysView />} />
				<Route path="/days/add" element={<DaysAdd />} />
				<Route path="/days/edit/:pageid" element={<DaysEdit />} />

				{/* daysoperators pages routes */}
				<Route path="/daysoperators" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/:fieldName/:fieldValue" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/index/:fieldName/:fieldValue" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/view/:pageid" element={<DaysoperatorsView />} />
				<Route path="/daysoperators/add" element={<DaysoperatorsAdd />} />
				<Route path="/daysoperators/edit/:pageid" element={<DaysoperatorsEdit />} />

				{/* devices pages routes */}
				<Route path="/devices" element={<DevicesList />} />
				<Route path="/devices/:fieldName/:fieldValue" element={<DevicesList />} />
				<Route path="/devices/index/:fieldName/:fieldValue" element={<DevicesList />} />
				<Route path="/devices/view/:pageid" element={<DevicesView />} />
				<Route path="/devices/add" element={<DevicesAdd />} />
				<Route path="/devices/edit/:pageid" element={<DevicesEdit />} />

				{/* divinatorytools pages routes */}
				<Route path="/divinatorytools" element={<DivinatorytoolsList />} />
				<Route path="/divinatorytools/:fieldName/:fieldValue" element={<DivinatorytoolsList />} />
				<Route path="/divinatorytools/index/:fieldName/:fieldValue" element={<DivinatorytoolsList />} />
				<Route path="/divinatorytools/view/:pageid" element={<DivinatorytoolsView />} />
				<Route path="/divinatorytools/add" element={<DivinatorytoolsAdd />} />
				<Route path="/divinatorytools/edit/:pageid" element={<DivinatorytoolsEdit />} />

				{/* feedback pages routes */}
				<Route path="/feedback" element={<FeedbackList />} />
				<Route path="/feedback/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/index/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/view/:pageid" element={<FeedbackView />} />
				<Route path="/feedback/add" element={<FeedbackAdd />} />
				<Route path="/feedback/edit/:pageid" element={<FeedbackEdit />} />

				{/* files pages routes */}
				<Route path="/files" element={<FilesList />} />
				<Route path="/files/:fieldName/:fieldValue" element={<FilesList />} />
				<Route path="/files/index/:fieldName/:fieldValue" element={<FilesList />} />
				<Route path="/files/view/:pageid" element={<FilesView />} />
				<Route path="/files/add" element={<FilesAdd />} />
				<Route path="/files/edit/:pageid" element={<FilesEdit />} />

				{/* filetypes pages routes */}
				<Route path="/filetypes" element={<FiletypesList />} />
				<Route path="/filetypes/:fieldName/:fieldValue" element={<FiletypesList />} />
				<Route path="/filetypes/index/:fieldName/:fieldValue" element={<FiletypesList />} />
				<Route path="/filetypes/view/:pageid" element={<FiletypesView />} />
				<Route path="/filetypes/add" element={<FiletypesAdd />} />
				<Route path="/filetypes/edit/:pageid" element={<FiletypesEdit />} />

				{/* hours pages routes */}
				<Route path="/hours" element={<HoursList />} />
				<Route path="/hours/:fieldName/:fieldValue" element={<HoursList />} />
				<Route path="/hours/index/:fieldName/:fieldValue" element={<HoursList />} />
				<Route path="/hours/view/:pageid" element={<HoursView />} />
				<Route path="/hours/add" element={<HoursAdd />} />
				<Route path="/hours/edit/:pageid" element={<HoursEdit />} />

				{/* hoursoperators pages routes */}
				<Route path="/hoursoperators" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/:fieldName/:fieldValue" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/index/:fieldName/:fieldValue" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/view/:pageid" element={<HoursoperatorsView />} />
				<Route path="/hoursoperators/add" element={<HoursoperatorsAdd />} />
				<Route path="/hoursoperators/edit/:pageid" element={<HoursoperatorsEdit />} />

				{/* jobs pages routes */}
				<Route path="/jobs" element={<JobsList />} />
				<Route path="/jobs/:fieldName/:fieldValue" element={<JobsList />} />
				<Route path="/jobs/index/:fieldName/:fieldValue" element={<JobsList />} />
				<Route path="/jobs/view/:pageid" element={<JobsView />} />
				<Route path="/jobs/add" element={<JobsAdd />} />
				<Route path="/jobs/edit/:pageid" element={<JobsEdit />} />

				{/* operatordevices pages routes */}
				<Route path="/operatordevices" element={<OperatordevicesList />} />
				<Route path="/operatordevices/:fieldName/:fieldValue" element={<OperatordevicesList />} />
				<Route path="/operatordevices/index/:fieldName/:fieldValue" element={<OperatordevicesList />} />
				<Route path="/operatordevices/view/:pageid" element={<OperatordevicesView />} />
				<Route path="/operatordevices/add" element={<OperatordevicesAdd />} />
				<Route path="/operatordevices/edit/:pageid" element={<OperatordevicesEdit />} />

				{/* operatordivinatorytools pages routes */}
				<Route path="/operatordivinatorytools" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/:fieldName/:fieldValue" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/index/:fieldName/:fieldValue" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/view/:pageid" element={<OperatordivinatorytoolsView />} />
				<Route path="/operatordivinatorytools/add" element={<OperatordivinatorytoolsAdd />} />
				<Route path="/operatordivinatorytools/edit/:pageid" element={<OperatordivinatorytoolsEdit />} />

				{/* operators pages routes */}
				<Route path="/operators" element={<OperatorsList />} />
				<Route path="/operators/:fieldName/:fieldValue" element={<OperatorsList />} />
				<Route path="/operators/index/:fieldName/:fieldValue" element={<OperatorsList />} />
				<Route path="/operators/view/:pageid" element={<OperatorsView />} />
				<Route path="/operators/edit/:pageid" element={<OperatorsEdit />} />

				{/* operatorstatuses pages routes */}
				<Route path="/operatorstatuses" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/:fieldName/:fieldValue" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/index/:fieldName/:fieldValue" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/view/:pageid" element={<OperatorstatusesView />} />
				<Route path="/operatorstatuses/add" element={<OperatorstatusesAdd />} />
				<Route path="/operatorstatuses/edit/:pageid" element={<OperatorstatusesEdit />} />

				{/* permissions pages routes */}
				<Route path="/permissions" element={<PermissionsList />} />
				<Route path="/permissions/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/index/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/view/:pageid" element={<PermissionsView />} />
				<Route path="/permissions/add" element={<PermissionsAdd />} />
				<Route path="/permissions/edit/:pageid" element={<PermissionsEdit />} />

				{/* qualifications pages routes */}
				<Route path="/qualifications" element={<QualificationsList />} />
				<Route path="/qualifications/:fieldName/:fieldValue" element={<QualificationsList />} />
				<Route path="/qualifications/index/:fieldName/:fieldValue" element={<QualificationsList />} />
				<Route path="/qualifications/view/:pageid" element={<QualificationsView />} />
				<Route path="/qualifications/add" element={<QualificationsAdd />} />
				<Route path="/qualifications/edit/:pageid" element={<QualificationsEdit />} />

				{/* roles pages routes */}
				<Route path="/roles" element={<RolesList />} />
				<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/view/:pageid" element={<RolesView />} />
				<Route path="/roles/add" element={<RolesAdd />} />
				<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

				{/* typesdocuments pages routes */}
				<Route path="/typesdocuments" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/:fieldName/:fieldValue" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/index/:fieldName/:fieldValue" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/view/:pageid" element={<TypesdocumentsView />} />
				<Route path="/typesdocuments/add" element={<TypesdocumentsAdd />} />
				<Route path="/typesdocuments/edit/:pageid" element={<TypesdocumentsEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
